import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import { CommonImageProps } from '~/types/common-props'
import { Loading } from '~/types/helper'

export interface CaseStudiesMock {
  title: string
  description: string
  color: string
  image: React.JSX.Element
  url: string
  infoLeftHead: string
  infoLeftText: string
  infoRightText: string
  stack: string[]
  infoRightHead?: string
  reverse?: boolean
  loading?: Loading
  category: string[]
}

const imageProps: CommonImageProps = {
  placeholder: 'blurred',
  loading: 'lazy',
}

const CaseStudiesCardsContent: CaseStudiesMock[] = [
  {
    reverse: true,
    title: 'Ammo Sales Platform',
    category: ['all cases', 'marketplace'],
    description:
      'Discover how we migrated an online ammo sales platform from WordPress to Ruby on Rails and Next.js. Also, we integrated a complex checkout system and automated regulatory compliance for a seamless and scalable web solution.',
    color: 'ammoColor',
    url: 'marketplace-platform-for-online-firearm-sales',
    infoLeftHead: 'Results',
    infoLeftText:
      'Improved the user experience by increasing page loading speed several times.',
    infoRightText:
      'Integrated a robust ordering system that makes shipping costs more accurate.',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-ammo.png"
          width={768}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
          loading="eager"
        />
      )
    },
    stack: [
      'ruby',
      'rails',
      'nextjs',
      'docker',
      'sql',
      'authorize',
      'elasticsearch',
      'mailgun',
      'easypost',
    ],
  },
  {
    title: 'Online domain name trading marketplace platform',
    category: ['all cases', 'marketplace'],
    description:
      'Dan is an online marketplace platform that allows you to buy and sell domain names simply and transparently.',
    color: 'danColor',
    url: 'marketplace-platform-for-trading-domain-names',
    infoLeftHead: 'Results',
    infoLeftText:
      'Made a big Portfolio Management update. Developed new options for better platform use.',
    infoRightText:
      'Delivered faster platform operations - less than 2 sec to load a page with 1000 domains.',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-dan.png"
          width={768}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
          loading="eager"
        />
      )
    },
    stack: [
      'ruby',
      'rails',
      'react',
      'typescript',
      'sql',
      'aws',
      'redis',
      'sidekiq',
      'adyen',
    ],
  },
  {
    reverse: true,
    title: 'Online Service Marketplace',
    category: ['all cases', 'marketplace'],
    description:
      'PlanMyKids is a marketplace where parents can plan and book kids activities nearby. The platform offers a simple solution that connects parents with after-school programs and camps.',
    color: 'pmkColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-pmk.png"
          width={575}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'kids-activities-service-marketplace',
    infoLeftHead: 'Results',
    infoLeftText:
      'Delivered a scalable MVP on time and below budget. This way, we were able to mitigate the main software development risks.',
    infoRightText:
      'Built a cross-platform progressive web app to enhance user experience for both desktop and smartphone users.',
    stack: ['rails', 'react', 'redux', 'aws'],
  },
  {
    title: 'Multi-vendor e-commerce marketplace platform',
    category: ['all cases', 'marketplace'],
    description:
      'This is the first online marketplace in Europe and the UK that allows users to buy and sell their services and products in one place.',
    color: 'malleniColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-malleni.png"
          width={575}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'custom-multi-vendor-marketplace-platform',
    infoLeftHead: 'Results',
    infoLeftText:
      'Built a complex marketplace that includes B2C and C2C environments.',
    infoRightText:
      'Created an enhanced design for all web solution’s parts to engage more users.',
    stack: [
      'ruby',
      'rails',
      'react',
      'nextjs',
      'sql',
      'aws',
      'elasticsearch',
      'redis',
      'docker',
    ],
  },
  {
    reverse: true,
    title: 'Insurance Progressive Web App',
    category: ['all cases', 'mvp'],
    description:
      'Babel Cover is an early stage insurance technology startup. The goal of this project was to bring consumers closer to insurance products, and to enable them to access insurance services from their smartphones with the help of a cross-platform Progressive Web App.',
    color: 'babelColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-babel.png"
          width={480}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'insurance-progressive-web-application',
    infoLeftHead: 'Results',
    infoLeftText: 'PWA created allowing to save costs and launch fast',
    infoRightText: 'Blockchain integration for safe payments processing',
    stack: ['rails', 'sql', 'react', 'redux', 'aws'],
  },
  {
    title: 'Online marketplace for boats',
    category: ['all cases', 'marketplace'],
    description:
      'Trade A Boat is an Australia-based marketplace that helps organize the process of buying and selling new or used boats, and marine equipment.',
    color: 'tradeaboatColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-tradeaboat.png"
          width={575}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'online-marketplace-for-boats',
    infoLeftHead: 'Results',
    infoLeftText: 'Delivered a modern, robust and fast loading web solution.',
    infoRightText: 'Increased conversion rate thanks to UX and UI redesign.',
    stack: ['ruby', 'rails', 'react', 'sql', 'aws'],
  },
  {
    reverse: true,
    title: 'Fitness Progressive Web Application',
    category: ['all cases', 'mvp'],
    description:
      'Impact is a fitness app that connects trainers and their clients via desktops and smartphones. The application allows for assigning and tracking personal training and nutrition programs.',
    color: 'impactColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-impact.png"
          width={575}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'fitness-progressive-web-application',
    infoLeftHead: '400+',
    infoLeftText: 'exercises included in the application',
    infoRightHead: '2000+',
    infoRightText: 'workouts performed with this cross-platform app',
    stack: ['ruby', 'rails', 'react', 'sql', 'aws', 'sidekiq'],
  },
  {
    title: 'Time tracking software',
    category: ['all cases', 'mvp'],
    description:
      'TimeBot is a Slack bot in conjunction with the admin panel that serves as the project management tool. It automates monitoring the workflow and evaluation of the performance, boosting the team’s productivity.',
    color: 'timebotColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-timebot-right.png"
          width={653}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'timebot-for-slack',
    infoLeftHead: 'Results',
    infoLeftText: 'Delivered a high-powered time tracking tool.',
    infoRightText: 'Implemented a transparent reporting system.',
    stack: ['nodejs', 'nestjs', 'react', 'sql', 'slack'],
  },
  {
    reverse: true,
    title: 'Real Estate Portal',
    category: ['all cases', 'saas'],
    description:
      'The SaaS allows for showing real estate listings with 3D virtual tours. The real estate portal lets users see an apartment on the screen as if they are already there. You can post a listing and change details if needed on the platform.',
    color: 'variaColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-varia-left.png"
          width={662}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'saas-real-estate-platform',
    infoLeftHead: 'Results',
    infoLeftText:
      'Our team developed a multi-page SaaS solution for real estate that connects homeowners, agents, and tenants.',
    infoRightText:
      'The platform allows you to post and manage property details and view them with 3D tours.',
    stack: ['ruby', 'react', 'sql', 'gatsby', 'aws'],
  },
  {
    title: 'Online travel marketplace',
    category: ['all cases', 'marketplace'],
    description:
      'iExpedition is an Australia-based expedition and adventure travel company that offers polar cruises. The travel marketplace platform allows tourists to search and book Arctic and Antarctic trips.',
    color: 'ixColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-iexpedition.png"
          width={480}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'travel-management-platform',
    infoLeftHead: '$2 mln',
    infoLeftText: 'worth of sales brought by the website within the first year',
    infoRightHead: '385%',
    infoRightText:
      'traffic growth for the past 2 years thanks to SEO-optimised website',
    stack: ['rails', 'sql', 'redis', 'jquery', 'rspec', 'activeAdmin'],
  },
  {
    reverse: true,
    title: 'Online collaboration marketplace',
    category: ['all cases', 'marketplace'],
    description:
      'Digital Village is a platform in Australia that brings a job network of digital professionals together to develop software and solve business technology issues.',
    color: 'dvColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-digital-village.png"
          width={662}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'collaboration-marketplace-and-smart-contract-system',
    infoLeftHead: 'Results',
    infoLeftText:
      'Built a complex service marketplace with team collaboration functionality.',
    infoRightText:
      'Created simple and clear UI and UX for excellent user experience.',
    stack: ['rails', 'sql', 'react', 'redux', 'aws'],
  },
  {
    title: 'Intelligent Job Match System',
    category: ['all cases', 'mvp'],
    description:
      'Wowner is an online job search solution for the Netherlands market. It helps people to find new relevant jobs based on their preferences and skills.',
    color: 'wownerColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-wowner.png"
          width={575}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'intelligent-job-matching-service',
    infoLeftHead: 'Results',
    infoLeftText:
      'Built a progresside web app to cut costs and time on native apps development and have effective solutions on all platforms and devices.',
    infoRightText:
      'Developed a ready-to-the-market job search platform within a short time frame. Integrated 3rd party databases for accurate search results.',
    stack: ['rails', 'react', 'aws', 'redux'],
  },
  {
    reverse: true,
    title: 'Recreational vehicles marketplace platform',
    category: ['all cases', 'marketplace'],
    description:
      'TradeRVs is a leading marketplace in Australia where private sellers and dealers can sell or buy new or used caravans, camper trailers, motorhomes, parts and accessories, and other recreational vehicles.',
    color: 'tradervsColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-tradervs.png"
          width={575}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'online-marketplace-for-caravans',
    infoLeftHead: 'Results',
    infoLeftText:
      'Redesigned the platform to make it user- and mobile-friendly.',
    infoRightText: 'Supply sides with third-party integrations.',
    stack: ['ruby', 'rails', 'react', 'sql', 'aws'],
  },
  {
    title: 'SaaS platform for bakery business',
    category: ['all cases', 'saas'],
    description:
      'CakerHQ is an Australian website for bakery businesses where cake makers sell flavorful desserts, and customers can book the confectionery easily and quickly.',
    color: 'cakerColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-caker.png"
          width={575}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'saas-platform-for-bakery-business',
    infoLeftHead: 'Results',
    infoLeftText:
      'Delivered an appealing and reliable SaaS solution from scratch.',
    infoRightText:
      'Made the process of ordering and paying for cakes easy and secure.',
    stack: ['rails', 'react', 'nextjs', 'stripe', 'googleCloud', 'aws'],
  },
  {
    reverse: true,
    title: 'Online marketplace for selling collectibles',
    category: ['all cases', 'marketplace'],
    description:
      'Learn how Codica created a collectible marketplace from scratch. The platform helps appraise and insure collectibles and is a place for social sharing.',
    color: 'chatticColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-chattic.png"
          width={680}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'online-marketplace-for-collectibles',
    infoLeftHead: 'Results',
    infoLeftText:
      'Delivered a stable platform that processes many-sided transactions with collectibles.',
    infoRightText:
      'Created a well-thought and responsive design facilitating user journey via the platform.',
    stack: [
      'react',
      'nextjs',
      'rails',
      'googleMaps',
      'stripe',
      'sendgrid',
      'aws',
      'shippo',
    ],
  },
  {
    title: 'Online news portal DUMA™',
    category: ['all cases', 'mvp'],
    description:
      'DUMA™ is the #1 entertainment news website in Africa. It shares articles and real-time news about celebrities, fashion, sports, lifestyle, and more.',
    color: 'dumaColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-duma.png"
          width={575}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'news-aggregator',
    infoLeftHead: 'Results',
    infoLeftText:
      'Implemented a high-load news aggregator with the ability to scale.',
    infoRightText:
      'Improved the page’s load speed time by 2 times on mobile devices.',
    stack: ['ruby', 'rails', 'aws', 'sidekiq', 'redis', 'gitlab', 'js'],
  },
  {
    reverse: true,
    title: 'Dental Imaging Management System',
    category: ['all cases', 'saas'],
    description:
      'Ref Dental is a dental imaging management system that allows the imaging center to exchange referral forms and communicate with dental clinics.',
    color: 'refColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-ref.png"
          width={575}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'dental-imaging-management-system',
    infoLeftHead: 'Results',
    infoLeftText:
      'Digitized cumbersome paper-based processes to help the dental imaging center save time and money on referrals management.',
    infoRightText:
      'Built an online dental portal MVP to help the customer test the initial business idea and improve the product based on feedback from early users.',
    stack: ['react', 'rails', 'aws', 'redis', 'gitlab'],
  },
  {
    title: 'Multi-vendor vehicle marketplace',
    category: ['all cases', 'marketplace'],
    description:
      'This is the first vehicle marketplace platform in Africa, and it consists of 90+ independent marketplaces, fully localized for each country. The marketplace was initially designed, developed and optimised with focus on mobile experience.',
    color: 'africarColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-africar.png"
          width={500}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'multi-vendor-vehicle-marketplace',
    infoLeftHead: '90+',
    infoLeftText: 'online marketplaces operating as part of the platform',
    infoRightHead: '430%',
    infoRightText:
      'traffic growth for the past 2 years thanks to SEO-optimised website',
    stack: [
      'rails',
      'sql',
      'redis',
      'sidekiq',
      'jquery',
      'rspec',
      'activeAdmin',
    ],
  },
  {
    reverse: true,
    title: 'Recruitment Progressive Web Application for RecruitEverywhere',
    category: ['all cases', 'mvp'],
    description:
      'RecruitEverywhere is a product of the Intelligence Group that specializes in talent acquisition and labor markets. The recruiting platform allows hiring specialists all over the world in a couple of clicks.',
    color: 'rewColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-rew.png"
          width={773}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'recruitment-progressive-web-application',
    infoLeftHead: 'Results',
    infoLeftText:
      'Created an online recruitment platform enabling organizations to hire staff members globally.',
    infoRightText:
      'Helped the customer save on mobile app development by implementing the PWA approach.',
    stack: ['rails', 'react', 'redux', 'aws'],
  },
  {
    title: 'Accommodation Search Website',
    category: ['all cases', 'mvp'],
    description:
      'This is a world-first service for finding long-term accommodation. The company set out to simplify the relocation process, and help people who move to Australia for work to quickly find new accommodation.',
    color: 'xpcColor',
    get image() {
      return (
        <StaticImage
          src="../../assets/images/case-study/previews/case-xpc.png"
          width={707}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    url: 'accommodation-search-website-mvp',
    infoLeftHead: 'Results',
    infoLeftText:
      'MVP delivered can be presented to investors and further scaled',
    infoRightText: 'Search wizard and Google Maps integration for improved UX',
    stack: ['react', 'redux', 'ant', 'semantic', 'cssAnimation', 'googleMaps'],
  },
]

export default CaseStudiesCardsContent
