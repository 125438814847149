import { useCallback, useState } from 'react'

import { ReactComponent as IconOnlineMarketplace } from '~/assets/images/icons/case-filter-icon-1.svg'
import { ReactComponent as IconSaaS } from '~/assets/images/icons/case-filter-icon-2.svg'
import { ReactComponent as IconMvp } from '~/assets/images/icons/case-filter-icon-3.svg'
import { cx } from '~/utils'

import * as containerStyles from './CaseStudiesFilters.module.scss'

const casesCategoryButton = [
  { name: 'All Cases' },
  { name: 'Marketplace', Icon: IconOnlineMarketplace },
  { name: 'SaaS', Icon: IconSaaS },
  { name: 'MVP', Icon: IconMvp },
]

const CaseStudiesFilters = ({ handleChange }) => {
  const [selected, setSelected] = useState('all cases')

  const handleClick = useCallback(
    (category) => {
      setSelected(category)
      handleChange({ target: { value: category } })
    },
    [handleChange],
  )

  return (
    <div className="container container-md">
      <div className={containerStyles.caseStudiesFilters__container}>
        {casesCategoryButton.map(({ name, Icon }) => (
          <div
            key={name}
            onClick={() => handleClick(name.toLowerCase())}
            className={cx(
              containerStyles.caseStudiesFilters__category,
              selected === name.toLowerCase() &&
                containerStyles.caseStudiesFilters__active,
            )}
          >
            {Icon && <Icon style={{ width: 28, height: 25 }} />}
            {name}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CaseStudiesFilters
