import { useState } from 'react'

import HeadPagesBlock from '~/components/blocks/HeadPagesBlock'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'

import * as containerStyles from './CaseStudies.module.scss'
import CaseStudiesCardsContent from './CaseStudiesCardsContent'
import CaseStudiesCards from './components/CaseStudiesCards'
import CaseStudiesCategory from './components/CaseStudiesCategory'
import CaseStudiesFilters from './components/CaseStudiesFilters'

const CaseStudies = () => {
  const [selected, setSelected] = useState('all cases')

  const handleChange = ({ target: { value } }) => {
    setSelected(value)
  }

  const casesContent = CaseStudiesCardsContent.filter((caseStudy) =>
    caseStudy.category.includes(selected),
  )

  return (
    <MainLayout scrollTop>
      <section className={containerStyles.caseStudiesContainer}>
        <HeadPagesBlock
          title="Discover our works"
          description="We have helped successfully launch clients’ projects of different stages: from product idea validation, MVP implementation to scaling, further development and support."
          widthDescription="950px"
          marginDescription="20px auto 40px"
        />

        <CaseStudiesFilters handleChange={handleChange} />

        <CaseStudiesCategory selected={selected} />

        <CaseStudiesCards dataContent={casesContent} />
      </section>

      <section className={containerStyles.caseStudiesContainer__formSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </section>
    </MainLayout>
  )
}

export default CaseStudies
