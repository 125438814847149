import { ReactNode } from 'react'

import * as containerStyles from './HeadPagesBlock.module.scss'

interface HeadPagesBlockProps {
  title: string
  description?: ReactNode
  descriptionLink?: string
  descriptionLinkName?: string
  widthDescription?: string
  marginDescription?: string
}

const HeadPagesBlock = ({
  title,
  description,
  descriptionLink,
  widthDescription,
  marginDescription,
  descriptionLinkName,
}: HeadPagesBlockProps) => (
  <div className={containerStyles.headPagesBlock}>
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <h1 className={containerStyles.headPagesBlock__title}>{title}</h1>
          {description && (
            <div
              className={containerStyles.headPagesBlock__description}
              style={{
                maxWidth: widthDescription,
                margin: marginDescription,
              }}
            >
              {description}
            </div>
          )}
          {descriptionLink && (
            <div className={containerStyles.headPagesBlock__descriptionLink}>
              <a href={descriptionLink}>{descriptionLinkName}</a>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
)

export default HeadPagesBlock
