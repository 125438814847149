import * as containerStyles from './CaseStudiesCategory.module.scss'
import { CaseStudiesCategoryMock } from './CaseStudiesCategoryMock'

type CaseStudiesCategoryProps = {
  selected: string
}

const CaseStudiesCategory = ({ selected }: CaseStudiesCategoryProps) => {
  const categoryInfo = CaseStudiesCategoryMock.find(
    (item) => item.category === selected,
  ) || { title: '', description: '' }

  return (
    <div className="container container-lg">
      <div className="row">
        <div className="col-12">
          <div className={containerStyles.caseStudiesCategory}>
            <h2 className={containerStyles.caseStudiesCategory__title}>
              {categoryInfo.title}
            </h2>

            {categoryInfo.description && (
              <p className={containerStyles.caseStudiesCategory__description}>
                {categoryInfo.description}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaseStudiesCategory
