export interface CaseStudiesCategoryMockProps {
  title: string
  category: string
  description?: string
}

export const CaseStudiesCategoryMock: CaseStudiesCategoryMockProps[] = [
  {
    title: 'All cases',
    category: 'all cases',
  },
  {
    title: 'Marketplace Development',
    category: 'marketplace',
    description:
      'From niche-specific platforms to vast multi-vendor hubs, we create tailored marketplaces that redefine how people buy and sell online. Boost your online presence with our marketplace development services to innovate your brand.',
  },
  {
    title: 'SaaS Development',
    category: 'saas',
    description:
      'Grow your business with scalable SaaS solutions that adapt and develop alongside your evolving needs. Our SaaS development services use cloud-native technologies and microservices architecture to deliver flexible and resilient platforms.',
  },
  {
    title: 'MVP Development',
    category: 'mvp',
    description:
      'Our MVP development services are synonymous with rapid prototyping, iterative innovation engines, and strategic roadmaps. We prioritize user-centric design, scalability, and performance, ensuring each product version satisfies your business needs.',
  },
]
