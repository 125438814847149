// extracted by mini-css-extract-plugin
export var africarColor = "c4_xC";
export var ammoColor = "c4_xj";
export var babelColor = "c4_xB";
export var cakerColor = "c4_xm";
export var chatticColor = "c4_xk";
export var danColor = "c4_xl";
export var dumaColor = "c4_xv";
export var dvColor = "c4_xF";
export var impactColor = "c4_xq";
export var ixColor = "c4_xG";
export var malleniColor = "c4_xp";
export var pmkColor = "c4_xz";
export var refColor = "c4_xw";
export var rewColor = "c4_xx";
export var timebotColor = "c4_xs";
export var tradeaboatColor = "c4_xt";
export var tradervsColor = "c4_xr";
export var variaColor = "c4_xn";
export var wownerColor = "c4_xy";
export var xpcColor = "c4_xD";